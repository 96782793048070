import React, { useState, useEffect, useContext } from "react"
import SEO from "components/seo"
import { useQueryParam, NumberParam } from "use-query-params"
import Button from 'components/button'
import { MainContext } from 'context/context'

import { getJob } from 'utils'

export default ({

}) => {
    const {
        options: {
            careerAboutStrikeforce,
            careerApplicationInstructions
        }
    } = useContext(MainContext)

    const [jobData, setJobData] = useState({})
    const [isLoading, setIsloading] = useState(true)

    const jobId = useQueryParam("id", NumberParam)[0]

    let excerpt = ''

    useEffect(() => {
        getJob(jobId)
        .then(response => {
			setIsloading(false)
			const { job } = response.data

			setJobData(job)
		}).catch(_error => {
			setIsloading(false)
		})
    }, [])

    const {
        title,
        location,
        employment_status,
        description,
        classifications
    } = jobData

    return (
        <>
            {!isLoading ?
                <>
                <SEO title={title } description={excerpt } />
                <div className="single-career">
                    <div className="single-career-hero">
                        <div className="container">
                            <h1 className="h2" dangerouslySetInnerHTML={{ __html: title }} />
                        </div>
                        <div className="tri">
                            <div></div>
                        </div>
                    </div>
                    <div className="container">
                        <div className="mobile-details">
                            <aside>
                                <div className="box">
                                    <h4 dangerouslySetInnerHTML={{ __html: 'Details' }} />
                                    <ul>
                                        <li className="location">
                                            <div>
                                                <p>
                                                    { location }
                                                </p>
                                            </div>
                                        </li>
                                        <li className="type">
                                            <p>
                                                { employment_status }
                                            </p>
                                        </li>
                                        <li className="classification">
                                            <div>
                                                {[].map((row, index) => {
                                                    return (
                                                        <p key={index}>{ row.node.name }</p>
                                                    )
                                                })}
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </aside>
                        </div>
                        <div className="main">
                            <div className="description" dangerouslySetInnerHTML={{ __html: description }} />
                            <div className="about-box">
                                <h2 dangerouslySetInnerHTML={{ __html: careerAboutStrikeforce.title }} />
                                <div className={'copy'} dangerouslySetInnerHTML={{ __html: careerAboutStrikeforce.copy }} />
                                { careerAboutStrikeforce.button &&
                                    <Button to={careerAboutStrikeforce.button.url} label={careerAboutStrikeforce.button.title} />
                                }
                            </div>
                        </div>
                        <aside>
                            <div className="box">
                                <h4 dangerouslySetInnerHTML={{ __html: 'Details' }} />
                                <ul>
                                    <li className="location">
                                        <p>
                                            { location }
                                        </p>
                                    </li>
                                    <li className="type">
                                        <p>
                                            { employment_status }
                                        </p>
                                    </li>
                                    <li className="classification">
                                        <div>
                                            {classifications && classifications.map((row, index) => {
                                                return (
                                                    <p key={index}>{ row.node.name }</p>
                                                )
                                            })}
                                        </div>
                                    </li>
                                </ul>
                                { careerApplicationInstructions.title && careerApplicationInstructions.copy &&
                                    <>
                                        <h4 dangerouslySetInnerHTML={{ __html: careerApplicationInstructions.title }} />
                                        <div className={'copy'} dangerouslySetInnerHTML={{ __html: careerApplicationInstructions.copy }} />
                                    </>
                                }
                                <Button label="Apply directly here" type="button" url={`/job-application?id=${ jobData.id }`} />
                            </div>
                        </aside>
                    </div>
                </div>
                </>
                : null
            }
        </>
    )
}